import ABI from "./abi";
import { ethers } from "ethers";
import _ from "lodash";

// Each entry in this config becomes available for use.
// e.g. useContract("... key from this map...");
//
// The type of each one refers to an entry in `ContractTypes`.
// For the particular methods exposed per-type, see updateAbi.js
//
// TODO: consider loading this JSON config dynamically
const ContractConfigs = {
  member: {
    type: "SocietyMember",
    contractAddress: process.env.ETH_SOCIETY_MEMBER_K,
  },
  memberV1: {
    type: "SocietyMemberV1",
    contractAddress: process.env.ETH_SOCIETY_MEMBER_V1_K,
  },
  initialArtSale: {
    type: "SocietyInitialArtSale",
    contractAddress: process.env.ETH_SOCIETY_INITIAL_ART_SALE_K,
  },
  // TODO:
  // art2022Q1Sale5000: {
  //   type: "SocietyArtSale",
  //   contractAddress: "0xffff..."
  // },
  // art2022Q1Auction100: {
  //   type: "SocietyArtAuction",
  //   contractAddress: "0xffff..."
  // },
  // art2022Q1Auction1: {
  //   type: "SocietyArtAuction",
  //   contractAddress: "0xffff..."
  // },
};

// This defines how each type of contract (Member, Sale, Auction)
// is represented in state and how it is refreshed.
const ContractTypes = {
  SocietyMember: {
    // remaining memberships = maximumTotalMemberCount - totalSupply
    // has enough to proceed = totalSupply > enoughMembersToProceed
    initialState: {
      mode: 0,
      mintCount: 0,
      balance: 0,
      tokenIds: [],
      pendingTokenIds: [],
      goldBalance: 0,
      goldSupply: 0,
      totalSupply: 0,
      maximumTotalMemberCount: 5000,
      maximumGoldMemberCount: 2000,
      enoughMembersToProceed: 2000,
      salePrice: ethers.utils.parseEther("0.15"),
      mintsPerWallet: 2,
    },
    abi: ABI.SocietyMember,
    // The result of this call becomes the new state for the contract.
    refresh: async ({ contract, address }) => {
      // TODO: switch to multicall to reduce rpc overhead
      //        e.g. https://github.com/Uniswap/redux-multicall
      //             https://github.com/Uniswap/v3-periphery/blob/main/contracts/lens/UniswapInterfaceMulticall.sol
      let [
        mode,
        mintCount,
        balance,
        goldBalance,
        goldSupply,
        totalSupply,
        maximumTotalMemberCount,
        maximumGoldMemberCount,
        enoughMembersToProceed,
        salePrice,
        mintsPerWallet,
        pendingTokenIds,
      ] = await Promise.all([
        contract.mode(),
        !address ? 0 : contract.getMintCountByAddress(address),
        !address ? 0 : contract.balanceOf(address).then((n) => n.toNumber()),
        !address
          ? 0
          : contract.goldBalanceOf(address).then((n) => n.toNumber()),
        contract.goldSupply().then((n) => n.toNumber()),
        contract.totalSupply().then((n) => n.toNumber()),
        contract.MAXIMUM_TOTAL_MEMBER_COUNT().then((n) => n.toNumber()),
        contract.MAXIMUM_GOLD_MEMBER_COUNT().then((n) => n.toNumber()),
        contract.ENOUGH_MEMBERS_TO_PROCEED().then((n) => n.toNumber()),
        contract.SALE_PRICE(),
        contract.MINTS_PER_WALLET().then((n) => n.toNumber()),
        !address
          ? []
          : contract
              .queryFilter(
                contract.filters.PendingTransfer(address, null),
                0,
                "latest"
              )
              .then((results) =>
                results.map(({ args: { tokenId } }) => tokenId.toNumber())
              ),
      ]);
      let tokenIds = [];
      if (balance > 0) {
        // If there's a balance, roll up the transfers to enumerate.
        tokenIds = await enumerate(contract, address);
      }
      return {
        mode,
        mintCount,
        balance,
        tokenIds,
        pendingTokenIds,
        goldBalance,
        goldSupply,
        totalSupply,
        maximumTotalMemberCount,
        maximumGoldMemberCount,
        enoughMembersToProceed,
        salePrice,
        mintsPerWallet,
      };
    },
  },

  SocietyMemberV1: {
    initialState: {
      isApprovedForUpgrade: false,
      balance: 0,
      tokenIds: [],
    },
    abi: ABI.SocietyMemberV1,
    // The result of this call becomes the new state for the contract.
    refresh: async ({ contract, address }) => {
      let [balance] = await Promise.all([
        !address ? 0 : contract.balanceOf(address).then((n) => n.toNumber()),
      ]);
      let tokenIds = [];
      let isApprovedForUpgrade = false;
      if (balance > 0) {
        // If there's a balance, roll up the transfers to enumerate.
        [tokenIds, isApprovedForUpgrade] = await Promise.all([
          enumerate(contract, address),
          contract.isApprovedForAll(
            address,
            ContractConfigs.member.contractAddress
          ),
        ]);
      }
      return {
        balance,
        tokenIds,
        isApprovedForUpgrade,
      };
    },
  },

  SocietyInitialArtSale: {
    initialState: {
      balance: 0,
      tokenIds: [],
      totalSupply: 0,
    },
    abi: ABI.SocietyInitialArtSale,
    refresh: async ({ contract, address }) => {
      let [balance, totalSupply] = await Promise.all([
        !address ? 0 : contract.balanceOf(address).then((n) => n.toNumber()),
        contract.totalSupply().then((n) => n.toNumber()),
      ]);
      let tokenIds = [];
      if (balance > 0) {
        // If there's a balance, roll up the transfers to enumerate.
        tokenIds = await enumerate(contract, address);
      }
      return {
        balance,
        tokenIds,
        totalSupply,
      };
    },
  },
};

// This rolls-up transfers on `k` to and from `address`.
// It yield a list of the token IDs current held by `address`.
async function enumerate(k, address) {
  if (!address) {
    return [];
  }
  let filterFrom = k.filters.Transfer(address, null);
  let filterTo = k.filters.Transfer(null, address);
  let transfersTo = await k.queryFilter(filterTo, 0, "latest");
  let transfersFrom = await k.queryFilter(filterFrom, 0, "latest");
  let transfers = _.sortBy(
    transfersTo
      .concat(transfersFrom)
      .map(({ blockNumber, args: { from, to, tokenId } }) => ({
        blockNumber,
        from,
        to,
        tokenId: tokenId.toNumber(),
      })),
    "blockNumber"
  );
  let holdings = transfers.reduce((holds, t) => {
    if (t.from === address) {
      holds[t.tokenId] = false;
    }
    if (t.to === address) {
      holds[t.tokenId] = true;
    }
    return holds;
  }, {});
  return Object.keys(holdings)
    .filter((t) => holdings[t])
    .map(Number);
}

export const Contracts = _.mapValues(
  ContractConfigs,
  ({ type, contractAddress }) => ({
    ...ContractTypes[type],
    type,
    contractAddress,
  })
);
