import { configureStore, isPlain } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { ethers } from "ethers";

// Disable logging in production.
const noLogging = () => (next) => (action) => next(action);
const logger =
  process.env.NODE_ENV === "development" ? createLogger() : noLogging;

const store = configureStore({
  reducer: {
    web3: require("./web3").reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable: (v) => isPlain(v) || ethers.BigNumber.isBigNumber(v),
      },
    }).concat(logger),
});

if (typeof window !== "undefined") {
  store.dispatch(require("./web3").init());
}

export default store;
