import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import _ from "lodash";
import { Contracts } from "./contracts";

// This exposes our connection to the blockchain.
// UI that cares should check useIsConnected() to determine if a wallet is connected.
//
// Before a wallet is connected we can still access the EVM.
// This uses our _alchemyProvider to interact without a signer or injected provider.
// Any personalized contract state will contain "empty" or "zero" values (balances etc).
//
// After a wallet is connected, then this uses that injected provider and alchemy as fallback.
// The `signer` will no longer be `null` and personalized contract state will be accurate.
//

const ConnectionState = {
  disconnected: "disconnected",
  pending: "pending",
  failed: "failed",
  connected: "connected",
};

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        rinkeby: {
          4: `https://eth-rinkeby.alchemyapi.io/v2/${process.env.ALCHEMY_KEY}`,
        },
        mainnet: {
          1: `https://eth-mainnet.alchemyapi.io/v2/${process.env.ALCHEMY_KEY}`,
        },
      }[process.env.ETH_NETWORK_NAME],
    },
  },
};

// To avoid init during SSR
const isBrowser = typeof window !== "undefined";

let _web3Modal = null;
if (isBrowser) {
  _web3Modal = new Web3Modal({
    network: process.env.ETH_NETWORK_NAME,
    cacheProvider: true,
    disableInjectedProvider: false,
    providerOptions, // required
  });
}
let _alchemyProvider = null;
if (isBrowser) {
  _alchemyProvider = new ethers.providers.AlchemyWebSocketProvider(
    process.env.ETH_NETWORK_NAME,
    process.env.ALCHEMY_KEY
  );
}

let _current = null;
if (isBrowser) {
  _current = {
    provider: _alchemyProvider,
    signer: null, // until connected
    contracts: _.mapValues(
      Contracts,
      ({ contractAddress, abi }) =>
        new ethers.Contract(
          contractAddress,
          new ethers.utils.Interface(abi),
          _alchemyProvider
        )
    ),
  };
}

function _setProvider(provider) {
  _current.provider = provider;
  _current.contract = _.mapValues(_current.contracts, (contract) =>
    contract.connect(provider)
  );
}

function getCachedCurrent() {
  return _current;
}

async function reset() {
  _setProvider(_alchemyProvider);
  _current.signer = null;
  await _web3Modal.clearCachedProvider();
}

async function checkInjectedProvider(injectedProvider) {
  let actualNetwork = await injectedProvider.detectNetwork();
  let expectNetwork = ethers.providers.getNetwork(process.env.ETH_NETWORK_NAME);
  if (actualNetwork.chainId !== expectNetwork.chainId) {
    // TODO: consider a gentler version of this (e.g. warning banner w/ a button to do this)
    injectedProvider
      .send("wallet_switchEthereumChain", [
        {
          chainId: ethers.utils.hexValue(expectNetwork.chainId),
        },
      ])
      .then(() => window.location.reload())
      .catch((err) => console.log("declined to change chain"));

    throw new Error(
      `expected network ${expectNetwork.name} but using ${actualNetwork.name}`
    );
  }
}

async function setupConnectedSigner(instance) {
  let injectedProvider = new ethers.providers.Web3Provider(instance);
  await checkInjectedProvider(injectedProvider); // e.g. make sure we're on the right chain
  // We use a FallbackProvider to better scale under severe load.
  let provider = new ethers.providers.FallbackProvider(
    [
      {
        provider: injectedProvider,
        priority: 1, // First use the injected provider from their wallet.
      },
      {
        provider: _alchemyProvider,
        priority: 2, // Fallback to our alchemy provider if the injected provider fails.
      },
    ],
    /* quorum: */ 1 // let the first result be the answer
  );
  let signer = injectedProvider.getSigner();
  _setProvider(provider);
  _current.signer = signer;
}

// This action is dispatched upon app init.
const init = createAsyncThunk(
  "web3/init",
  async (args, { dispatch, signal, getState }) => {
    let injected = window.ethereum || window.web3;
    if (injected) {
      // The idea here is to detect when they're already connected.
      let accounts = await new ethers.providers.Web3Provider(
        injected
      ).listAccounts();
      if (accounts.length > 0) {
        // Since they were already connected, this won't trigger metamask UI etc.
        // But it will update all of our state and listeners.
        dispatch(connect());
        return; // we don't call multiDispatchFullRefresh() here because the connect flow will do it
      }
    }
    multiDispatchFullRefresh(dispatch);
  }
);

// This is big and expensive and we should only call it for huge changes.
// e.g. when the user or chain changes on us
function multiDispatchFullRefresh(dispatch, address = 0) {
  dispatch(refreshAllContracts({ address }));
  dispatch(refreshBalance({ address }));
  dispatch(resolveAddressName({ address }));
}

// This action is dispatched when they tap the "Connect" button.
// It's also dispatched upon init when they are already connected.
const connect = createAsyncThunk(
  "web3/connect",
  async (args, { dispatch, signal, getState }) => {
    if (_current.signer) {
      return getState().web3;
    }
    let instance = await _web3Modal.connect();
    await setupConnectedSigner(instance);
    instance.on("close", async () => {
      await reset();
      dispatch(connectionClosed());
    });
    instance.on("accountsChanged", async (accounts) => {
      dispatch(accountsChanged({ accounts }));
      let address = 0;
      if (accounts.length === 0) {
        await reset();
      } else {
        if (_current.signer === null) {
          await setupConnectedSigner(instance);
        }
        address = await _current.signer.getAddress();
      }
      multiDispatchFullRefresh(dispatch, address);
    });
    instance.on("chainChanged", async (chainId) => {
      dispatch(chainChanged({ chainId }));
      let address = 0;
      if (_current.signer) {
        address = await _current.signer.getAddress();
      }
      multiDispatchFullRefresh(dispatch, address);
    });
    const address = await _current.signer.getAddress();
    const { chainId } = await _current.provider.getNetwork();
    multiDispatchFullRefresh(dispatch, address);
    return {
      address,
      chainId,
    };
  }
);

const refreshAllContracts = createAsyncThunk(
  "web3/refreshAllContracts",
  async ({ address = null } = {}, { dispatch, signal, getState }) => {
    address = address || getState().web3.address;
    Object.keys(Contracts).forEach((kName) =>
      dispatch(refreshSingleContract({ kName, address }))
    );
  }
);

const refreshSingleContract = createAsyncThunk(
  "web3/refreshSingleContract",
  async ({ kName, address = null }, { dispatch, signal, getState }) => {
    let state = getState();
    let kState = state.web3.contracts[kName];
    address = address || state.web3.address;
    const newKState = await Contracts[kName]
      .refresh({
        contract: _current.contracts[kName],
        address,
        state: kState,
      })
      .catch((err) => {
        console.log("error refreshing", kName);
        console.log(err);
        throw err;
      });
    return {
      kName,
      kState: { ...kState, ...newKState },
    };
  }
);

const refreshBalance = createAsyncThunk(
  "web3/refreshBalance",
  async ({ address = null } = {}, { dispatch, signal, getState }) => {
    // no-op when disconnected
    if (!_current.signer) {
      return {
        balance: getState().web3.balance,
      };
    }
    const balance = await _current.signer.getBalance();
    return {
      balance,
    };
  }
);

const resolveAddressName = createAsyncThunk(
  "web3/resolveAddressName",
  async ({ address = null } = {}, { dispatch, signal, getState }) => {
    // no-op when disconnected
    if (!_current.signer) {
      return {
        addressName: getState().web3.addressName,
      };
    }
    address = address || getState().web3.address;
    let addressName = (await _current.provider.lookupAddress(address)) || "";
    return {
      addressName,
    };
  }
);

const INIT_STATE = {
  address: 0,
  addressName: "",
  connectionState: ConnectionState.disconnected,
  chainId: 0,
  balance: ethers.BigNumber.from("0"),
  contracts: _.mapValues(Contracts, ({ initialState }) => initialState),
};
const { actions, reducer } = createSlice({
  name: "web3",
  initialState: INIT_STATE,
  reducers: {
    connectionClosed: (state, action) => {
      return INIT_STATE;
    },
    accountsChanged: (state, action) => {
      let { accounts } = action.payload;
      if (accounts.length === 0) {
        return INIT_STATE;
      } else {
        state.address = accounts[0];
        state.connectionState = ConnectionState.connected;
      }
    },
    chainChanged: (state, action) => {
      let { chainId } = action.payload;
      state.chainId = chainId;
    },
  },
  extraReducers: {
    [connect.pending]: (state, action) => {
      state.connectionState = ConnectionState.pending;
    },
    [connect.fulfilled]: (state, action) => {
      let { address, chainId } = action.payload;
      state.connectionState = ConnectionState.connected;
      state.address = address;
      state.chainId = chainId;
    },
    [connect.rejected]: (state, action) => {
      state.connectionState = ConnectionState.failed;
    },
    [refreshSingleContract.fulfilled]: (state, action) => {
      let { kName, kState } = action.payload;
      state.contracts[kName] = kState;
    },
    [refreshBalance.fulfilled]: (state, action) => {
      let { balance } = action.payload;
      state.balance = balance;
    },
    [resolveAddressName.fulfilled]: (state, action) => {
      let { addressName } = action.payload;
      state.addressName = addressName;
    },
  },
});

const { connectionClosed, accountsChanged, chainChanged } = actions;

export {
  ConnectionState,
  getCachedCurrent,
  init,
  connect,
  refreshSingleContract,
  refreshAllContracts,
  refreshBalance,
  reducer,
};
