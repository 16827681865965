import React from "react";
import { Provider } from "react-redux";
import store from "./src/eth/store";

export const wrapRootElement = ({ element, props }) => {
  return <Provider store={store}>{element}</Provider>;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location;
  const scrollToTopRoutes = [`/terms`, `/404`, ``, `/`];
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    window.scrollTo(0, 0);
  }
  return false;
};
